<template>
  <div class="ps-section__right">
    <form class="ps-form--account-setting" method="post">
      <div class="ps-form__header">
        <h3>Edit Shop Hours</h3>
      </div>
      <HoursForm :shop-hours-id="this.$route.params.id"></HoursForm>
    </form>

  </div>
</template>

<script>
import HoursForm from "./HoursForm";
export default {
  name: "EditShopHours",
  components: { HoursForm },
  data() {
    return {
      loading: false,
      errors: null
    };
  },
}
</script>

<style scoped>

</style>