<template>
  <div class="ps-form__content">
    <div class="row mt-3 mb-3" v-if="message">
      <div class="col-12">
        <small class="text-danger" v-if="isError">{{ message }}</small>
        <small class="text-success" v-else>{{ message }}</small>
      </div>
    </div>
    <div class="form-group">
      <label>Day of the week<sup>*</sup> </label>
      <div class="form-group__content">
        <select v-model="form.day_of_week" class="form-control" id="day_of_week">
          <option value="Sunday">Sunday</option>
          <option value="Monday">Monday</option>
          <option value="Tuesday">Tuesday</option>
          <option value="Wednesday">Wednesday</option>
          <option value="Thursday">Thursday</option>
          <option value="Friday">Friday</option>
          <option value="Saturday">Saturday</option>
        </select>
        <small v-if="errors && errors.day_of_week" class="day_of_week text-danger">
          {{ errors.day_of_week[0] }}
        </small>
      </div>
    </div>
    <div class="form-group">
      <label>Opening Time<sup>*</sup> </label>
      <div class="form-group__content">
        <input class="form-control" v-model="form.start_hour" type="time" placeholder="" />
        <small v-if="errors && errors.start_hour" class="text-danger">
          {{ errors.start_hour[0] }}
        </small>
      </div>
    </div>
    <div class="form-group">
      <label>Closing Time<sup>*</sup> </label>
      <div class="form-group__content">
        <input class="form-control" v-model="form.end_hour" type="time" placeholder="" />
        <small v-if="errors && errors.end_hour" class="text-danger">
          {{ errors.end_hour[0] }}
        </small>
      </div>
    </div>
    <div class="form-group">
      <button class="ps-btn" @click.prevent="onSubmit()">
        {{ isLoading ? "Saving..." : "Save" }}
        <i v-if="!isLoading" class="icon icon-right-arrow ml-1"></i>
        <span v-if="isLoading" class="spinner-border text-light" role="status"></span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "HoursForm",
  props: {
    shopHoursId: String
  },
  data() {
    return {
      isLoading: false,
      form: {
        day_of_week: null,
        start_hour: null,
        end_hour: null,
      },
      loadingForm: true,
      errors: null,
      message: null,
      isError: false,
      baseUrl: 'shop-hours'
    }
  },
  created() {
     if (this.shopHoursId) {
       axios.get(`${this.baseUrl}/${this.shopHoursId}`).then((response) => {
          this.form = response.data
       });
     }
  },
  methods: {
      onSubmit() {
        if (this.shopHoursId) {
          this.updateShopHours()
        } else {
          this.createShopHours()
        }
      },

    updateShopHours() {
        this.isLoading = true;
        axios.put(`${this.baseUrl}/${this.shopHoursId}`, this.form).then((response) => {
          this.isLoading = false;
          if (response.status === 200) {
            this.message = 'Shop hours successfully updated'
            this.$router.push({name: 'merchant_hours'});
          }
        }).catch((error) => {
          if (error.response.status === 422) {
            this.message = "Shop hours could not be updated. Please enter all required fields.";
            this.errors = error.response.data.errors
          } else {
            this.message = error.backendErrors[0]
          }
          this.isError = true
          this.isLoading = false;
        });
      },

    createShopHours() {
        this.isLoading = true;
        axios.post(`${this.baseUrl}`, this.form).then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.message = 'Shop hours successfully created'
            this.$router.push({name: 'merchant_hours'});
          }
          this.isLoading = false;
        }).catch((error) => {
          if (error.response.status === 422) {
            this.message = "Shop hours could not be created. Please enter all required fields.";
            this.errors = error.response.data.errors
          } else {
            this.message = error.backendErrors[0]
          }
          this.isError = true
          this.isLoading = false;
        });
      }
  }
}
</script>

<style scoped>

</style>